import React, { useState } from 'react'
import Modal from 'react-modal'

// Images
import Close from 'img/x.svg'
import playCircle from 'img/play-circle.svg'
import VideoStill from 'img/tuinkamer.jpg'

import './VideoModal.scss'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
}

function VideoApp({ url }) {
  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div className="ervaring">
      <div className="ervaring-image-container" onClick={openModal}>
        <img
          className="ervaring-video-image"
          alt=""
          height="152"
          src={VideoStill}
        />
      </div>
      <div className="row position-relative ervaring-single-black-bar w-80 m-0 py-2 color-background-contrast">
        <div className="col-9" onClick={openModal}>
          <p className="m-0 font-size-sm font-weight-xl color-text-main">
            Unieke tuinkamer
          </p>
          <p className="m-0 font-size-sm font-weight-m color-text-main">
            Bekijk de video
          </p>
        </div>
        <div className="col-3 d-flex justify-content-center align-items-center">
          <img alt="" src={playCircle} width="30" />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="VideoModal"
      >
        <div className="text-right">
          <button onClick={closeModal}>
            <img src={Close} alt="" />
          </button>
        </div>
        <iframe
          src={url}
          width="640"
          height="360"
          frameBorder="0"
          title="Tuinkamer"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </Modal>
    </div>
  )
}

export default VideoApp
