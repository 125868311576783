/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// CSS
import 'styles/Ervaringen.scss'

// Images
import orangeArrow from 'img/orange_arrow.svg'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { BannerCustom } from 'components/Elements'
import CustomLink from 'components/CustomLink'
import BrandSlider from 'components/Brands/BrandSlider'
import VideoModal from 'components/VideoModal/VideoModal'

const Ervaringen = () => {
  const {
    ervaringPagina: {
      acf: {
        page_content: {
          banner: ervaringBanner,
          banner2: ervaringBanner2,
          image: {
            localFile: {
              childImageSharp: { fluid: ervaringImage },
            },
          },
        },
      },
      yoast_meta: yoast,
    },
    ervaringen: { edges: ervaringenList },
  } = useStaticQuery(graphql`
    {
      ervaringPagina: wordpressPage(wordpress_id: { eq: 13 }) {
        acf {
          page_content {
            banner {
              button_text
              text
            }
            banner2 {
              button_text
              text
            }
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
        yoast_meta {
          name
          content
          property
        }
      }
      ervaringen: allWordpressWpErvaring(sort: { fields: date, order: DESC }) {
        edges {
          node {
            acf {
              preview {
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                location
                quote
                small_introduction
                title
              }
            }
            path
            slug
          }
        }
      }
    }
  `)

  const chunk = (array, chunkSize) => {
    const R = []
    for (let i = 0; i < array.length; i += chunkSize) {
      R.push(array.slice(i, i + chunkSize))
    }
    return R
  }

  const [chunkSize, setChunkSize] = useState(6)

  const updateChunk = () => {
    setChunkSize(chunkSize + 6)
  }

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="ervaringen">
        <BackgroundImage
          loading="eager"
          className="smaller-background-image"
          alt=""
          fluid={ervaringImage}
        />
        <BannerCustom
          to="/showroomafspraak-maken"
          className="color-background-secondary"
          bannerText={ervaringBanner.text}
          bannerButtonText={ervaringBanner.button_text}
        />
        <div className="container py-5">
          <h2 className="m-0 text-center font-size-xl font-weight-xl color-text-main">
            Onze ervaringen
          </h2>
          <p className="m-0 text-center font-size-sm font-weight-xl color-text-contrast">
            Hier vindt u onze eerdere succesvolle ondernemingen, bekijk ze door
            en doe inspiratie op!
          </p>
          <div className="row pt-3 ">
            {chunk(ervaringenList, chunkSize)[0].map((ervaring, index) => {
              if (index === 2) {
                return <Ervaring key={index} className="col-12 col-lg-4 mb-5" />
              }
              return (
                <Ervaring
                  key={index}
                  className="col-12 col-lg-4 mb-5"
                  ervaring={ervaring.node}
                />
              )
            })}
          </div>
          <div className="d-flex justify-content-center">
            {chunk(ervaringenList, chunkSize).length > 1 && (
              <div
                tabIndex="0"
                role="button"
                className="color-background-contrast font-size-xm font-weight-xl color-text-light px-4 py-2 text-uppercase"
                onKeyDown={() => updateChunk()}
                onClick={() => updateChunk()}
              >
                Laad meer
              </div>
            )}
          </div>
        </div>
        <BannerCustom
          to="/contact"
          className="color-background-main"
          bannerText={ervaringBanner2.text}
          bannerButtonText={ervaringBanner2.button_text}
        />
        <BrandSlider title="Onze Merken" className="py-5 my-5" />
      </div>
    </Layout>
  )
}

export default Ervaringen

const Ervaring = ({ ervaring, className }) => {
  return (
    <>
      {ervaring ? (
        <div className={`ervaring ${className ? `${className}` : ``}`}>
          <CustomLink to={`/ervaringen/${ervaring.slug}`}>
            <div className="ervaring-image-container">
              <Img
                className="ervaring-image"
                alt=""
                fluid={
                  ervaring.acf.preview.image.localFile.childImageSharp.fluid
                }
              />
            </div>
            <div className="row position-relative ervaring-single-black-bar w-80 m-0 py-2 color-background-main">
              <div className="col-10">
                <p className="m-0 font-size-sm font-weight-xl color-text-light">
                  {ervaring.acf.preview.title}
                </p>
                <p className="m-0 font-size-sm font-weight-m color-text-light">
                  {ervaring.acf.preview.location}
                </p>
              </div>
              <div className="col-2 d-flex justify-content-center align-items-center">
                <img alt="" src={orangeArrow} />
              </div>
            </div>
          </CustomLink>
        </div>
      ) : (
        <div className={`ervaring ${className ? `${className}` : ``}`}>
          <VideoModal url="https://player.vimeo.com/video/418775968" />
        </div>
      )}
    </>
  )
}
